<template>
  <v-card>
    <v-card-title>Pellet-新規</v-card-title>
    <template>
      <v-spacer></v-spacer>
      <template v-if="apierror.status == 'error'">
        <div
          v-for="msg of apierror.messages"
          :key="msg"
        >
          <v-row class="ml-6 mb-3 ma-3">
            <span style="color: red">* {{ msg }} </span>
          </v-row>
        </div>
      </template>
    </template>
    <v-spacer></v-spacer>
    <v-form class="ma-6">
      <v-row>
        <v-col
          cols="12"
          md="2"
        >
          <label for="pelletID"> Pellet ID<span style="color: red">*</span> </label>
        </v-col>
        <v-col
          cols="12"
          md="5"
        >
          <v-text-field
            id="pellet_id"
            v-model="$v.PelletForm.pellet_id.$model"
            outlined
            hide-details="auto"
            dense
            placeholder="PelletID 英数字only"

            :error-messages="idErrors"
            :counter="20"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          md="2"
        >
          <label for="pelletName"> Pellet名(日本語)<span style="color: red">*</span> </label>
        </v-col>
        <v-col
          cols="12"
          md="8"
        >
          <v-text-field
            id="pellet_name"
            v-model="$v.PelletForm.pellet_name_jpn.$model"
            outlined
            hide-details="auto"
            dense
            placeholder="pellet 日本語名"
            :error-messages="nameErrorsJpn"
            :counter="100"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          md="2"
        >
          <label for="pelletName"> Pellet名(中国語)<span style="color: red">*</span> </label>
        </v-col>
        <v-col
          cols="12"
          md="8"
        >
          <v-text-field
            id="pellet_name"
            v-model="$v.PelletForm.pellet_name_chn.$model"
            outlined
            hide-details="auto"
            dense
            placeholder="pellet 中国語名"
            :error-messages="nameErrorsChn"
            :counter="100"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          md="2"
        >
          <label for="pelletName"> Pellet名(英語)<span style="color: red">*</span> </label>
        </v-col>
        <v-col
          cols="12"
          md="8"
        >
          <v-text-field
            id="pellet_name"
            v-model="$v.PelletForm.pellet_name_eng.$model"
            outlined
            hide-details="auto"
            dense
            placeholder="pellet 中国語名"
            :error-messages="nameErrorsEng"
            :counter="100"
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- <v-row align="center">
        <v-col
          cols="12"
          md="2"
        >
          <label for="note">イメージ</label>
        </v-col>
        <v-col
          cols="12"
          md="5"
        >
          <v-file-input
            v-model="pellet_img"
            filled
            :prepend-icon="icons.mdiCameraOutline"
            hide-details
            @change="Preview_image"
          ></v-file-input>
        </v-col>
        <v-col
          cols="12"
          md="5"
        >
          <v-img
            :src="url"
            width="120px"
            height="90px"
            contain
          ></v-img>
        </v-col>
      </v-row> -->

      <v-row align="center">
        <v-col
          offset-md="5"
          cols="12"
        >
          <v-btn
            color="primary"
            :loading="submitStatus"
            @click="submit(PelletForm)"
          >
            Submit
          </v-btn>
          <v-btn
            class="mx-2"
            outlined
            @click="cancelClick"
          >
            Cancel
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
import {
  mdiCameraOutline,
} from '@mdi/js'
import {
  required, maxLength,
} from 'vuelidate/lib/validators'
import {
  mapMutations,
  mapActions,
} from 'vuex'

export default {
  data: () => ({
    submitStatus: false,
    changeFlag: false,
    url: null,
    icons: {
      mdiCameraOutline,
    },

    PelletForm: {
      pellet_id: '',
      pellet_name_jpn: '',
      pellet_name_chn: '',
      pellet_name_eng: '',

    },
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
  }),

  validations: {
    PelletForm: {
      pellet_id: {
        required,
        maxLength: maxLength(20),
      },
      pellet_name_jpn: {
        required,
        maxLength: maxLength(100),
      },
      pellet_name_chn: {
        required,
        maxLength: maxLength(100),
      },
      pellet_name_eng: {
        required,
        maxLength: maxLength(100),
      },
    },
  },
  computed: {
    idErrors() {
      const errors = []
      if (!this.$v.PelletForm.pellet_id.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.PelletForm.pellet_id.maxLength && errors.push('長すぎます！')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.PelletForm.pellet_id.required && errors.push('必須項目')

      return errors
    },

    nameErrorsJpn() {
      const errors = []
      if (!this.$v.PelletForm.pellet_name_jpn.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.PelletForm.pellet_name_jpn.maxLength && errors.push('Name must be at most 100 characters long')

      // eslint-disable-next-line no-unused-expressions
      !this.$v.PelletForm.pellet_name_jpn.required && errors.push('必須項目')

      return errors
    },

    nameErrorsChn() {
      const errors = []
      if (!this.$v.PelletForm.pellet_name_chn.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.PelletForm.pellet_name_chn.maxLength && errors.push('Name must be at most 100 characters long')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.PelletForm.pellet_name_chn.required && errors.push('必須項目')

      return errors
    },

    nameErrorsEng() {
      const errors = []
      if (!this.$v.PelletForm.pellet_name_eng.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.PelletForm.pellet_name_eng.maxLength && errors.push('Name must be at most 100 characters long')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.PelletForm.pellet_name_eng.required && errors.push('必須項目')

      return errors
    },

  },
  watch: {
    PelletForm: {
      handler() {
        this.changeFlag = true
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    cancelClick() {
      if (this.changeFlag) {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('他ページに遷移します。よろしいでしょうか？')) {
          this.jumpToListPage()
        }

        return
      }
      this.jumpToListPage()
    },
    jumpToListPage() {
      this.$router.push({
        path: '/pellet-list',
        query: this.$route.params.query ?? {
        },
      })
    },
    ...mapActions('pelletStore', ['createPellet']),

    Preview_image() {
      console.log(this.pellet_img)
      console.log('PelletForm null')
      if (this.pellet_img) {
        this.url = URL.createObjectURL(this.pellet_img)
        this.PelletForm.pellet_img = this.pellet_img
      } else {
        this.url = ''

        this.PelletForm.pellet_img = ''
      }
    },

    submit(PelletForm) {
      // if (this.pellet_img == null) {
      //   // eslint-disable-next-line no-restricted-globals
      //   if (!confirm('イメージが空です。続行しますか')) {
      //     return
      //   }
      // }
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.submitStatus = true
        this.setOverlayStatus(true)
        this.createPellet(PelletForm)
          .then(() => {
            this.setOverlayStatus(false)
            this.jumpToListPage()
          })
          .catch(error => {
            this.setOverlayStatus(false)
            if (error.response) {
              this.apierror.status = 'error'
              this.apierror.code = error.response.data.code
              this.apierror.messages = error.response.data.message
            }
          }).finally(() => {
            this.submitStatus = false
          })
      } else {
        console.log('error submit!!')
      }
    },
  },
}
</script>
